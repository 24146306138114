<template>
  <!-- v-transition:enter="transition ease-out duration-100" v-transition:enter-start="transform opacity-0 scale-95" v-transition:enter-end="transform opacity-100 scale-100" v-transition:leave="transition ease-in duration-75" v-transition:leave-start="transform opacity-100 scale-100" v-transition:leave-end="transform opacity-0 scale-95" -->

  <!-- classes for sticky footer: https://dev.to/timosville/sticky-footer-using-tailwind-css-225p-->
  <div id="app" class="flex flex-col min-h-screen">
    <!--   ref='main'-->

    <modal v-show="doShowSigninModal" :do-skip-header="true" :do-skip-footer="true" @submit="submitSignin"
      @cancel="doShowSigninModal = false" style="z-index: 11"> <!-- hacky, needed to be done to render over the registration button //-->
      <template #body>
        <div class="max-w-md w-full">
          <div>
            <h2 class="mt-6 text-center text-2xl sm:text-3xl leading-9 font-bold text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <form class="mt-8" @submit.prevent="submitSignin">
            <div class="rounded-md shadow-sm">
              <div>
                <input v-model="loginPayload.email" aria-label="Email address" name="email" type="email" required
                  class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-blue-500/50 focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                  placeholder="Email address">
              </div>
              <div class="-mt-px">
                <input v-model="loginPayload.password" aria-label="Password" name="password" type="password" required
                  class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-blue-500/50 focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                  placeholder="Password">
              </div>
            </div>

            <div class="mt-6 flex items-center justify-between">
              <div class="flex items-center">
                <a href="javascript:void(0)"
                  class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                  @click="showPasswordResetModal">Forgot your password?</a>
              </div>
            </div>

            <div class="mt-6">
              <button type="submit"
                class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-red-500/50 active:bg-red-700 transition duration-150 ease-in-out">
                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg class="h-5 w-5 text-red-500 group-hover:text-red-400 transition ease-in-out duration-150"
                    fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd" />
                  </svg>
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </template>
    </modal>

    <modal v-show="doShowPasswordResetModal" :do-skip-header="true" :do-skip-footer="true" @submit="submitPasswordReset"
      @cancel="doShowPasswordResetModal = false">
      <template #body>
        <div class="max-w-md w-full">
          <div>
            <h2 class="mt-6 text-center text-2xl sm:text-3xl leading-9 font-bold text-gray-900">
              Password Reset
            </h2>
          </div>
          <div class="mt-4">
            Please enter your email address below. If we have your email-address
            on file, we will send you instructions on how to reset your
            password.
          </div>

          <form class="mt-4" @submit.prevent="submitPasswordReset">
            <input v-model="passwordResetEmail" aria-label="Email address" name="email" type="email" required
              class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
              placeholder="Email address">

            <div class="mt-6">
              <button type="submit"
                class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out">
                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg class="h-5 w-5 text-red-500 group-hover:text-red-400 transition ease-in-out duration-150"
                    fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd" />
                  </svg>
                </span>
                Request Email
              </button>
            </div>
          </form>
        </div>
      </template>
    </modal>

    <modal v-show="showChangePasswordModal" header="Reset Password" affirmative-button-label="Reset Password"
      :disable-submit="passwordSubmitDisabled" @submit="submitChangePassword(resetToken)"
      @cancel="showChangePasswordModal = false">
      <template #body>
        <div>
          <dl class="mt-4 mb-4 sm:-ml-6 sm:-mr-6">
            <div class="py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
              <dt class="text-sm leading-9 font-medium text-gray-500">
                New Password
              </dt>
              <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                <password v-model="resetPasswordInput" default-class="form-input block w-full" :toggle="true"
                  @feedback="passwordFeedBack" @score="passwordScoreFeedback" />
                <div v-if="passwordSuggestion" class="text-blue-600">
                  {{ passwordSuggestion }}
                </div>
              </dd>
            </div>
          </dl>
        </div>
      </template>
    </modal>

    <nav class="bg-red-700">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <router-link to="/">
                <img src="@/assets/ebm_logo.png" alt="European Broker Meeting" width="225">
              </router-link>
            </div>
            <div class="flex-shrink-0 ml-4">
              <a href="javascript:void(0)" @click='toSponsorSite'>
                <img src="@/assets/inrego_header4.png" alt="Inrego" width="80">
              </a>
            </div>
            <div class="hidden lg:block">
              <div class="ml-6 flex items-center">
                <router-link to="/register" v-if="!user" class="mt-1 nav-item"
                  :class="{ 'nav-item--active': topLevelRoute === 'register' }">
                  Register
                </router-link>
                <router-link to="/program" class="mt-1 nav-item"
                  :class="{ 'nav-item--active': topLevelRoute === 'program' }">
                  Program
                </router-link>
                <router-link v-if="user" to="/scheduler" class="mt-1 nav-item"
                  :class="{ 'nav-item--active': topLevelRoute === 'scheduler' }">
                  Scheduler
                </router-link>
                <router-link to="/attendees" class="mt-1 nav-item"
                  :class="{ 'nav-item--active': topLevelRoute === 'attendees' }">
                  Attendees
                </router-link>
                <!-- <router-link v-if="user" to="/leads" class="mt-1 nav-item"
                  :class="{ 'nav-item--active': topLevelRoute === 'leads' }">
                  Leads
                </router-link> -->
                <router-link to="/sponsors" class="mt-1 nav-item"
                  :class="{ 'nav-item--active': topLevelRoute === 'sponsors' }">
                  Sponsors
                </router-link>
                <router-link v-if="!user" to="/testimonials" class="mt-1 nav-item" :class="{
                  'nav-item--active': topLevelRoute === 'testimonials',
                }">
                  Testimonials
                </router-link>
                <router-link to="/pictures" class="mt-1 nav-item"
                  :class="{ 'nav-item--active': topLevelRoute === 'pictures' }">
                  Pictures
                </router-link>
                <router-link v-if="!user" to="/contact" class="mt-1 nav-item"
                  :class="{ 'nav-item--active': topLevelRoute === 'contact' }">
                  Contact
                </router-link>
              </div>
            </div>
          </div>
          <div class="hidden lg:block">
            <div class="ml-4 flex items-center lg:ml-6">
              <div v-if="user" class="ml-3 relative">
                <div>
                  <button v-on-clickaway="closeUserDropdown"
                    class="relative p-1 border-2 border-transparent text-red-300 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-red-600"
                    aria-label="Notifications" @click="isUserDropdownOpen = !isUserDropdownOpen">
                    <div v-if="profilePic" class="h-8 w-8 rounded-full" alt="" :style="userPicCSS" />
                    <svg v-else class="h-6 w-6" stroke="none" fill="none" viewBox="2 2 16 16">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
                        fill="currentColor" />
                    </svg>
                  </button>
                </div>

                <!-- Include transitions here -->
                <div v-show="isUserDropdownOpen"
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-20">
                  <div class="py-1 rounded-md bg-white shadow-xs">
                    <router-link to="/profile" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                      Your Profile
                    </router-link>
                    <a href="javascript:void(0)" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      @click="signoutWrap">Sign out</a>
                  </div>
                </div>
              </div>
              <button v-if="!user"
                class="font-semibold relative p-1 border-2 border-transparent text-white rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-red-600"
                @click="showSignInModal">
                Sign In
              </button>
            </div>
            <!--  <div v-else class="ml-4 flex items-center lg:ml-6">
              <button class="ml-4 px-3 py-2 rounded-md text-sm font-medium text-red-200 hover:text-white hover:bg-red-600 focus:outline-none focus:text-white focus:bg-red-600" aria-label="Sign in">
                Sign In
              </button>
            </div> -->
          </div>
          <div class="-mr-2 flex lg:hidden">
            <button
              class="inline-flex items-center justify-center p-2 rounded-md text-red-300 hover:text-white hover:bg-red-600 focus:outline-none focus:bg-red-600 focus:text-white"
              :aria-label="isUserDropdownOpen ? 'Close main menu' : 'Main menu'" :aria-expanded="isUserDropdownOpen"
              @click.stop="isUserDropdownOpen = !isUserDropdownOpen">
              <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path :class="{
                  hidden: isUserDropdownOpen,
                  'inline-flex': !isUserDropdownOpen,
                }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                <path :class="{
                  hidden: !isUserDropdownOpen,
                  'inline-flex': isUserDropdownOpen,
                }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="lg:hidden" :class="{ block: isUserDropdownOpen, hidden: !isUserDropdownOpen }">
        <div class="px-2 pt-2 pb-3 sm:px-3">
          <router-link to="/register" class="mt-1 nav-item-mobile" :class="{
            'nav-item-mobile--active': topLevelRoute === 'register',
          }">
            Register
          </router-link>
          <router-link to="/program" class="mt-1 nav-item-mobile" :class="{
            'nav-item-mobile--active': topLevelRoute === 'program',
          }">
            Program
          </router-link>
          <router-link v-if="user" to="/scheduler" class="mt-1 nav-item-mobile" :class="{
            'nav-item-mobile--active': topLevelRoute === 'scheduler',
          }">
            Scheduler
          </router-link>

          <router-link to="/attendees" class="mt-1 nav-item-mobile" :class="{
            'nav-item-mobile--active': topLevelRoute === 'attendees',
          }">
            Attendees
          </router-link>
          <router-link v-if="user" to="/leads" class="mt-1 nav-item-mobile" :class="{
            'nav-item-mobile--active': topLevelRoute === 'leads',
          }">
            Leads
          </router-link>

          <router-link to="/sponsors" class="mt-1 nav-item-mobile" :class="{
            'nav-item-mobile--active': topLevelRoute === 'sponsors',
          }">
            Sponsors
          </router-link>
          <router-link v-if="!user" to="/testimonials" class="mt-1 nav-item-mobile" :class="{
            'nav-item-mobile--active': topLevelRoute === 'testimonials',
          }">
            Testimonials
          </router-link>
          <router-link to="/pictures" class="mt-1 nav-item-mobile" :class="{
            'nav-item-mobile--active': topLevelRoute === 'pictures',
          }">
            Pictures
          </router-link>
          <router-link v-if="!user" to="/contact" class="mt-1 nav-item-mobile" :class="{
            'nav-item-mobile--active': topLevelRoute === 'contact',
          }">
            Contact
          </router-link>
        </div>

        <div v-if="user" class="pt-4 pb-3 border-t border-red-800">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <div v-if="profilePic" class="h-8 w-8 rounded-full" alt="" :style="userPicCSS" />
              <svg v-else class="h-6 w-6" stroke="none" fill="none" viewBox="2 2 16 16">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
                  fill="white" />
              </svg>
            </div>
            <div class="ml-3">
              <div class="text-base font-medium leading-none text-white">
                {{ user.name }}
              </div>
              <div class="mt-1 text-sm font-medium leading-none text-red-300">
                {{ user.email }}
              </div>
            </div>
          </div>
          <div class="mt-3 px-2" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <router-link to="/profile" class="mt-1 nav-item-mobile" role="menuitem" :class="{
              'nav-item-mobile--active': topLevelRoute === 'profile',
            }">
              Your Profile
            </router-link>
            <a href="javascript:void(0)" class="mt-1 nav-item-mobile" role="menuitem" @click="signoutWrap">Sign out</a>
          </div>
        </div>
        <div v-else class="pt-1 pb-3 border-t border-red-800">
          <div class="mt-3 px-2" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <a href="javascript:void(0)" class="mt-1 nav-item-mobile" role="menuitem" @click="showSignInModal">Sign In</a>
          </div>
        </div>
      </div>
    </nav>
    <nav v-if="user" class="bg-red-100">
      <div class="max-w-7xl mx-auto px-4 py-1 sm:px-6 lg:px-8 text-red-900 text-sm">
        <div class="float-left pb-1.5">
          <span>Welcome {{ user.name }}</span>
          <span v-if="!user.function">
            - To complete your profile, please
            <router-link to="/profile" class="inline-block underline">enter your function</router-link></span>          
          <span v-else-if="!profilePic">
            - To complete your profile, please
            <router-link to="/profile" class="inline-block underline">upload your profile picture</router-link></span>
        </div>
        <div class="float-right hidden md:block">
          <span class="pr-2">HKG {{this.loadWorldClock(tzHkg)}}</span><span class="pr-2">|</span>
          <span class="pr-2">CYP {{this.loadWorldClock(tzCyp)}}</span><span class="pr-2">|</span>
          <span class="pr-2">AMS {{this.loadWorldClock(tzAms)}}</span><span class="pr-2">|</span>
          <span class="pr-2">LDN {{this.loadWorldClock(tzLdn)}}</span><span class="pr-2">|</span>
          <span class="pr-2">NYC {{this.loadWorldClock(tzNyc)}}</span><span class="pr-2">|</span>
          <span class="pr-0">LA {{this.loadWorldClock(tzLa)}}</span>
        </div>
      </div>
    </nav>
    <nav v-else>
      <div class="max-w-7xl mx-auto px-4 py-1 sm:px-6 lg:px-8 text-red-900 text-sm">
        <div class="float-right hidden md:block text-sm">
          <span class="pr-2">HKG {{this.loadWorldClock(tzHkg)}}</span><span class="pr-2">|</span>
          <span class="pr-2">CYP {{this.loadWorldClock(tzCyp)}}</span><span class="pr-2">|</span>
          <span class="pr-2">AMS {{this.loadWorldClock(tzAms)}}</span><span class="pr-2">|</span>
          <span class="pr-2">LDN {{this.loadWorldClock(tzLdn)}}</span><span class="pr-2">|</span>
          <span class="pr-2">NYC {{this.loadWorldClock(tzNyc)}}</span><span class="pr-2">|</span>
          <span class="pr-0">LA {{this.loadWorldClock(tzLa)}}</span>
        </div>
      </div>
    </nav>

    <header v-if="doIncludeSplash">
      <slot name="splash" />
    </header>

    <main class="flex-grow">
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

        <div v-if="!doSkipPic" class='w-full h-24 sm:h-48 md:h-72 lg:h-96 bg-cover relative'
          style="background-image:url('/images/main_banner_cyprus1.png')">

                  <div class="homeImageText">The 20th European Broker Meeting<br>
        October 10 &amp; 11, 2024 at the Parklane Cyprus</div>


          <a href='https://www.thebrokersite.com' target="_blank" class="homePoweredBy2"
            style="background-image:url('/images/powered_by_tbs_new.png')"
            title="The European Broker Meeting is powered by www.TheBrokerSite.com">
          </a>
          <div class="grid grid-cols-2 gap-4">

            <div v-if="!isCountdownEnded()" class="countdownPanel w-1/2 bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-4 px-2 sm:px-6 mb-4 text-center left-1/4 top-36 hidden lg:block">
              <div class="mt-2"><strong>Countdown to #EBM2024:</strong></div>
              <div class="grid grid-cols-4 px-24">
                <div><span class="text-5xl text-red-600 font-bold">{{formatCountdownNumber(days)}}</span><br>days</div>
                <div><span class="text-5xl text-red-600 font-bold">{{formatCountdownNumber(hours)}}</span><br>hours</div>
                <div><span class="text-5xl text-red-600 font-bold">{{formatCountdownNumber(minutes)}}</span><br>min</div>
                <div><span class="text-5xl text-red-600 font-bold">{{formatCountdownNumber(seconds)}}</span><br>sec</div>
              </div>
            </div>

            <router-link v-if="!user" to="/register"
              class="register-home rounded-md border border-transparent bg-red-600 py-4 px-8 text-l sm:text-2xl font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-lg">Register</router-link>

            <!-- <a href="https://www.marriott.com/events/start.mi?id=1701967514188&key=GRP"
              class="hidden sm:inline-block bookhotel-home rounded-md border border-transparent bg-red-600 py-4 px-8 text-l sm:text-2xl font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-lg" target="_blank" rel="noopener">Book your hotel</a> -->
            <a href="/program#regInfo"
              class="hidden sm:inline-block bookhotel-home rounded-md border border-transparent bg-red-600 py-4 px-8 text-l sm:text-2xl font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-lg">Book your hotel</a>

          </div>


        </div>

        <!--  LOGIN
        <div class="px-4 py-0 sm:px-0">
          <nav class="hidden sm:flex items-center text-sm leading-5 font-medium">
            <span class="ml-3 shadow-sm rounded-md">
              <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700 transition duration-150 ease-in-out">
                Log In
              </button>
            </span>
            <a href="#" class="text-red-500 hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Log In</a>
            <a href="#" class="text-red-500 hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Forgot your password?</a>
            <router-link to="/payment" class="text-red-500 hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Register as a paid member</router-link>
          </nav>
        </div> -->

        <div class="px-4 sm:py-2 sm:px-0">
          <slot name="main" />
        </div>
      </div>

      <div v-if="!user && doIncludeExtraContent" class="hidden lg:block">

        <div class="mt-6 w-full bg-red-100">
          <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-16 text-center">
            <h2 class="text-4xl text-bold m-4">What others are saying about the European Broker meeting:</h2>
            <transition name="slide-left-right" mode="out-in">
              <div :key="currentTestimonialIndex">
                <p>{{ currentTestimonial.testimonial }}</p>
                <strong>{{ currentTestimonial.sender }}</strong>
              </div>
            </transition>
            <br><br>
            <strong>
              <router-link to="/testimonials" class="text-red-600 hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">
                See our testimonials
              </router-link>
            </strong>
          </div>
        </div>

        <div class="mt-6 w-full">
          <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div class="custom-grid-left">
              <div>
                <img src="@/assets/picmain01.png" style="width: 348px;">
              </div>
              <div class="text-center px-16">
                <h2 class="text-4xl text-bold m-4">Connecting industry leaders</h2>
                <div>
                  For <strong>20 years</strong>, the European Broker Meeting has been the place for networking, business and entertainment within the IT trading industry. Our attendees are companies specializing in desktops, laptops, tablets, servers, printers, mobile phones, networking and POS equipment.<br><br>
                  <strong><router-link to="/attendees" class="text-red-600 hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Check out who is attending #EBM2024</router-link></strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6 w-full bg-red-100">
          <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div class="custom-grid-right">
              <div class="text-center px-16">
                <h2 class="text-4xl text-bold m-4">Top-notch networking locations</h2>
                <div>
                  Every year, <strong>we bring the IT trading industry together</strong> in various locations across Europe. From the Netherlands to Greece, we provide only the best locations! At our event venues, we provide multiple areas for attendees to network and socialize. In addition, we also have areas where tables can be reserved for meetings.<br><br>
                  <strong><router-link to="/scheduler" class="text-red-600 hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Schedule your meetings</router-link></strong>
                </div>
              </div>
              <div>
                <img src="@/assets/picmain02.png" style="width: 348px;">
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6 w-full">
          <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div class="custom-grid-left">
              <div>
                <img src="@/assets/picmain03.png" style="width: 348px;">
              </div>
              <div class="text-center px-16">
                <h2 class="text-4xl text-bold m-4">Room for entertainment</h2>
                <div>
                  The European Broker Meeting not only focuses on business, but there is also room for entertainment and relaxation. During the event, we organize <strong>amazing parties</strong> where colleagues and industry peers can unwind and enjoy themselves together.<br><br>
                  <strong><router-link to="/pictures" class="text-red-600 hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">See the pictures of our previous meetings</router-link></strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6 w-full bg-red-100">
          <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            <div class="custom-grid-right">
              <div class="text-center px-16">
                <h2 class="text-4xl text-bold m-4">Part of The Broker Site</h2>
                <div>
                  The European Broker Meeting is <strong>only open to members of The Broker Site</strong>. This is to ensure that the companies attending the event are trustworthy. For the past 25 years The Broker Site has been the B2B marketplace to buy and sell new, used and refurbished hardware!<br><br>
                  <strong><a href="https://www.thebrokersite.com/about" class="text-red-600 hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out" target="_blank" rel="noopener">Learn more about The Broker Site</a></strong>
                </div>
              </div>
              <div>
                <img src="@/assets/picmain04.png" style="width: 348px;">
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6 w-full">
          <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-16 text-center">
            <h2 class="text-5xl text-bold m-4">Get your tickets for #EBM2024!</h2><br>


            <div class="md:grid grid-cols-2 gap-4">
              <div class="flex flex-col justify-between mt-2 w-full bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-2 sm:px-6 mb-4 text-center">
                <div>
                  <h3 class="text-2xl text-bold m-4 text-red-700">TBS Members</h3>
                  <div class="flex justify-center">
                    <ul class="mt-4 text-left">
                      <li class="flex items-start mb-2">
                        <div class="flex-shrink-0">
                          <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700">
                          Access to the European Broker Meeting
                        </p>
                      </li>
                      <li class="flex items-start mb-2">
                        <div class="flex-shrink-0">
                          <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700">
                          Lunch, snacks and drinks during the event
                        </p>
                      </li>
                      <li class="flex items-start mb-2">
                        <div class="flex-shrink-0">
                          <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700">
                          Access to the amazing dinner parties
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="my-6">
                  <!-- <span class="diagonal-strikethrough mr-2"><strong>&euro; 995</strong></span>
                  <span><strong>&euro; 895</strong></span> -->
                  <span><strong>&euro; 995</strong></span>
                  <!-- <br>
                  <span class="italic text-gray-400 text-sm">*Until the 1st of July 2024</span> -->
                  <br>
                  For each attendee, &euro;100 is donated to <a href="https://www.it4kids.com/en/home-en/" target="_blank" rel="noopener" class="text-red-700">IT4Kids</a>!
                  <br><br>
                  <router-link v-if="!user" to="/register" class="mt-8 rounded-md border border-transparent bg-red-600 py-4 px-8 text-l sm:text-2xl font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-lg">Register</router-link>
                </div>
              </div>
              <div class="flex flex-col justify-between mt-2 w-full bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-2 sm:px-6 mb-4 text-center">
                <div>
                  <h3 class="text-2xl text-bold m-4 text-red-700">Non TBS Members</h3>
                  <div class="flex justify-center">
                    <ul class="mt-4 text-left">
                      <li class="flex items-start mb-2">
                        <div class="flex-shrink-0">
                          <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700">
                          1 year TBS Plus Membership
                        </p>
                      </li>
                      <li class="flex items-start mb-2">
                        <div class="flex-shrink-0">
                          <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700">
                          Access to the European Broker Meeting
                        </p>
                      </li>
                      <li class="flex items-start mb-2">
                        <div class="flex-shrink-0">
                          <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700">
                          Lunch, snacks and drinks during the event
                        </p>
                      </li>
                      <li class="flex items-start mb-2">
                        <div class="flex-shrink-0">
                          <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700">
                          Access to the amazing dinner parties
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="my-6">
                  <!-- <span class="diagonal-strikethrough mr-2"><strong>&euro; 1670</strong></span>
                  <span><strong>&euro; 1570 (first attendee)</strong></span> -->
                  <span><strong>&euro; 1670 (first attendee)</strong></span>
                  <br>
                  <!-- <span class="diagonal-strikethrough mr-2"><strong>&euro; 995</strong></span>
                  <span><strong>&euro; 895 (other attendees)</strong></span> -->
                  <span><strong>&euro; 995 (other attendees)</strong></span>
                  <!-- <br>
                  <span class="italic text-gray-400 text-sm">*Until the 1st of July 2024</span> -->
                  <br>
                  For each attendee, &euro;100 is donated to <a href="https://www.it4kids.com/en/home-en/" target="_blank" rel="noopener" class="text-red-700">IT4Kids</a>!
                  <br><br>
                  <router-link v-if="!user" to="/register" class="mt-8 rounded-md border border-transparent bg-red-600 py-4 px-8 text-l sm:text-2xl font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-lg">Register</router-link>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

        <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <!-- banners -->
        <div class="px-4 mt-10 mb-4 invisible lg:visible">
          <div v-if="showVideoBanner" class="flex -mx-8">
            <div v-for="(banner, index) in bannersEnriched" :key="banner.id" :alt="banner.alt" class="banner-item w-1/5 px-8">
              <iframe v-if='index==2' width="185" height="185" :src="videoBannerUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <a v-else href="javascript:void(0)" class="rounded bg-white shadow border-b border-gray-200 h-full block"
                :style="banner.style" @click="navigateBanner(banner)" />
            </div>
          </div>
          <div v-else class="flex -mx-8">
            <div v-for="(banner) in bannersEnriched" :key="banner.id" :alt="banner.alt" class="banner-item w-1/5 px-8">
              <a href="javascript:void(0)" class="rounded bg-white shadow border-b border-gray-200 h-full block"
                :style="banner.style" @click="navigateBanner(banner)" />
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- footer -->
    <div class="bg-gray-900">
      <div class="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav class="-mx-5 -my-2 flex flex-wrap justify-center">
          <div class="px-5 py-2">
            <router-link to="/" class="text-base leading-6 text-gray-300 hover:text-white">
              Home
            </router-link>
          </div>
          <div class="px-5 py-2" v-if="!user">
            <router-link to="/register" class="text-base leading-6 text-gray-300 hover:text-white">
              Register
            </router-link>
          </div>
          <div class="px-5 py-2">
            <router-link to="/program" class="text-base leading-6 text-gray-300 hover:text-white">
              Program
            </router-link>
          </div>
          <div class="px-5 py-2" v-if="user">
            <router-link to="scheduler" class="text-base leading-6 text-gray-300 hover:text-white">
              Scheduler
            </router-link>
          </div>
          <div class="px-5 py-2">
            <router-link to="/attendees" class="text-base leading-6 text-gray-300 hover:text-white">
              Attendees
            </router-link>
          </div>
          <div class="px-5 py-2" v-if="user">
            <router-link to="/leads" class="text-base leading-6 text-gray-300 hover:text-white">
              Leads
            </router-link>
          </div>
          <div class="px-5 py-2">
            <router-link to="/Sponsors" class="text-base leading-6 text-gray-300 hover:text-white">
              Sponsors
            </router-link>
          </div>
          <div class="px-5 py-2">
            <router-link to="/testimonials" class="text-base leading-6 text-gray-300 hover:text-white">
              Testimonials
            </router-link>
          </div>
          <div class="px-5 py-2">
            <router-link to="/pictures" class="text-base leading-6 text-gray-300 hover:text-white">
              Pictures
            </router-link>
          </div>
          <div class="px-5 py-2">
            <router-link to="/contact" class="text-base leading-6 text-gray-300 hover:text-white">
              Contact
            </router-link>
          </div>
          <div class="px-5 py-2">
            <a href="https://www.thebrokersite.com" target="_blank"
              class="text-base leading-6 text-gray-300 hover:text-white">
              The Broker Site
            </a>
          </div>
        </nav>
        <div class="mt-8 flex justify-center gap-4">
          <a href="https://twitter.com/ebm_news" rel="noopener" target="_blank"
            class="ml-6 text-gray-300 hover:text-white">
            <span class="sr-only">Twitter</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>

          <a href="https://www.linkedin.com/company/european-broker-meeting/" rel="noopener" target="_blank"
            class="text-gray-300 hover:text-white">
            <span class="sr-only">LinkedIn</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z" />
            </svg>
          </a>

          <a href="https://www.facebook.com/events/2406680349647520/" rel="noopener" target="_blank"
            class="text-gray-300 hover:text-white">
            <span class="sr-only">Facebook</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clip-rule="evenodd" />
            </svg>
          </a>
        </div>
        <div class="mt-8">
          <p class="text-center text-base leading-6 text-gray-400">
            &copy; Copyright 1999 - {{ new Date().getFullYear() }} The Broker
            Site BV. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mixin as clickaway } from "vue-clickaway";
import { mapState, mapActions, mapGetters } from "vuex";
import eventBus from "../utils/eventBus";
import Modal from "./modal.vue";
import loginFlow from "../mixins/loginFlow";

export default {
  name: "BaseLayout",
  components: {
    Modal,
  },
  mixins: [loginFlow, clickaway],
  props: {
    doSkipPic: Boolean,
    doSkipHeader: Boolean,
    doIncludeSplash: Boolean,
    doIncludeExtraContent: Boolean,
    withHeaderStructure: Boolean,
  },

  data() {
    return {
      isUserDropdownOpen: false,
      tzHkg: 'Asia/Hong_Kong',
      tzAms: 'Europe/Amsterdam',
      tzLdn: 'Europe/London',
      tzCyp: 'Europe/Nicosia',
      tzNyc: 'America/New_York',
      tzLa: 'America/Los_Angeles',
      allSettings: null,
      showVideoBanner: null,
      videoBannerUrl: null,
      randomTestimonial: null,
      targetDate: new Date('2024-10-09T13:00:00Z'),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      testimonials: [],
      currentTestimonialIndex: 0      
    };
  },
  async mounted() {
    const { cats, testimonials } = await this.$http.get("/api/misc/testimonials").then(res => res.data.payload)
    // we get the largest (cats contains the category IDs), because that is the newest group of testmonials
    const largestId = cats.reduce((max, item) => item.id > max ? item.id : max, 0)
    this.testimonials = testimonials.filter(item => item.catId === largestId)
    this.startCountdown()
    this.startTestimonialRotation()
  },

  computed: {
    ...mapState(["route", "user", "profilePic"]),
    ...mapGetters(["bannersEnriched", "userPicCSS"]),
    topLevelRoute() {
      return this.route.path.slice(1).split("/")[0];
    },
    isSmallScreen() {
      return window.innerWidth < 500;
    },
    currentTestimonial() {
      return this.testimonials[this.currentTestimonialIndex];
    },    
  },
  async created() {
    try {
      this.allSettings = await this.$http.get("/api/misc/allSettings").then(res => res.data.payload)
      this.showVideoBanner = Boolean(Number(this.allSettings.showVideoBanner))
      this.videoBannerUrl = this.allSettings.videoBannerUrl
    } catch (err) {
      console.log("err", err)
    }

    this.searchCat = this.user ? "broadcasts" : "broadcasts";

    if (!this.$route.query.reset) return;

    this.resetToken = this.$route.query.reset;
    const tokenVerifyPayload = await this.verifyResetToken({
      resetToken: this.$route.query.reset,
    });

    const tokenInvalid = tokenVerifyPayload.status !== 200
      || (tokenVerifyPayload.data.payload
        && !tokenVerifyPayload.data.payload.isSuccess);

    if (tokenInvalid) {
      const errorMessage = tokenVerifyPayload.data.payload.msg;
      eventBus.$emit("createSnackbar", {
        type: "error",
        title: "Password reset token invalid",
        body: errorMessage,
        timeoutInMS: 5000,
      });
      return;
    }
    this.showChangePasswordModal = true;
  },
  methods: {
    ...mapActions(["incrementClickForBanner"]),
    closeUserDropdown() {
      if (this.isUserDropdownOpen) {
        this.isUserDropdownOpen = false;
      }
    },
    navigateBanner(banner) {
      if (banner.isAd) {
        this.incrementClickForBanner(banner.id);
      }
      window.open(banner.url, "_blank");
    },
    toSponsorSite() {
      window.open("https://www.inrego.com", "_blank");
    },
    loadWorldClock(strTimeZone) {
      var tDate = new Date();
      const option={ timeZone: strTimeZone, hourCycle: 'h23', hour: '2-digit', minute: '2-digit' }
      return tDate.toLocaleTimeString('en-US', option)
    },

    startCountdown() {
      this.updateCountdown();
      setInterval(this.updateCountdown, 1000);
    },
    updateCountdown() {
      const now = new Date().getTime();
      const distance = this.targetDate.getTime() - now;

      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(this.updateCountdown);
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;
      }
    },
    formatCountdownNumber(value) {
      return value.toString().padStart(2, '0');
    },
    isCountdownEnded() {
      return this.days == 0 && this.hours == 0 && this.minutes == 0 && this.seconds == 0 ? true : false;
    },
    startTestimonialRotation() {
      setInterval(() => {
        this.currentTestimonialIndex = (this.currentTestimonialIndex + 1) % this.testimonials.length;
      }, 8000); // Change testimonial every 5 seconds
    },    
  },
};
</script>

<style>
.banner-item:before {
  /* dynamic height */
  content: "";
  float: left;
  padding-top: 100%;
}

.custom-grid-left {
  display: grid;
  grid-template-columns: 400px 1fr;
}
.custom-grid-right {
  display: grid;
  grid-template-columns: 1fr 400px;
}

.diagonal-strikethrough {
  position: relative;
  display: inline-block;
  color: black;
}

.diagonal-strikethrough::before {
  content: '';
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 3px solid red;
  transform: rotate(+25deg);
  transform-origin: center center;
}

.slide-left-right-enter-active, .slide-left-right-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-left-right-enter {
  transform: translateX(200px);
  opacity: 0;
}
.slide-left-right-enter-to {
  transform: translateX(0);
  opacity: 1;
}
.slide-left-right-leave {
  transform: translateX(0);
  opacity: 1;
}
.slide-left-right-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}
</style>


```
